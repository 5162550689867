import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'
import Helmet from 'react-helmet'
import { LocaleContext } from '../components/layout'
import HeroForm from '../components/resources/hero-form'
import WatchElementAnimate from '../components/watch-element-animate'
import BottomForm from '../components/resources/bottom-form'
import MostPopular from '../components/resources/most-popular'
import Resources from '../components/resources/resources'
import { withPreview } from 'gatsby-source-prismic'
import SEO from '../components/SEO/SEO'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// styles

// markup
const ResourcesPage = ({
  data: { resources, resourcesList },
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const most_popular_resources = resources.data.most_popular_resources.filter(
    (m) => m.most_popular.uid != null
  )
  const featuredLocaleResourceList = resourcesList.nodes.filter(
    (r) =>
      typeof most_popular_resources.find((p) => p.most_popular.uid === r.uid) !=
      'undefined'
  )
  return (
    <main>
      <SEO
        title={resources.data.meta_title}
        desc={resources.data.meta_description}
        locale={locale}
      />
      
      <Resources resourcesList={resourcesList.nodes} i18n={i18n} />
      
    </main>
  )
}

export default withPreview(ResourcesPage)

export const pageQuery = graphql`
  query ResourcesQuery($locale: String!) {
    resources: prismicResources(lang: { eq: $locale }) {
      data {
        meta_description
        meta_title
        subscribe_form_headline {
          text
        }
        most_popular_resources {
          most_popular {
            uid
          }
        }
        ebook_form_subheadline {
          text
        }
        ebook_form_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        ebook_form_headline {
          text
        }
        ebook_form_link {
          url
        }
        subscribe_form_subheadline {
          text
        }
      }
    }
    resourcesList: allPrismicResource(
      sort: { fields: first_publication_date, order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      nodes {
        data {
          meta_title
          category
          meta_description
          content {
            html
          }
          link {
            url
          }
          image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        first_publication_date
        uid
      }
    }
  }
`
